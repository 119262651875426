import React from "react";

// Next
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// React Icons
import {
  HiOutlineAdjustments,
  HiOutlineLightBulb,
  HiViewGrid,
} from "react-icons/hi";

// Components
import { Button } from "@/components/common/Button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/common/Card";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";

const arrayNav = [
  {
    title: "CRUD prototype",
    desc: "Access listing and manage tickets",
    link: "login",
    icon: <HiViewGrid className="w-10 h-10 text-blue-500" />,
    id: "ticket-crud",
  },
  {
    title: "Back-end status",
    desc: "Access status of the API Endpoints, generate default tokens",
    link: "status",
    icon: <HiOutlineAdjustments className="w-10 h-10 text-green-500" />,
    id: "back-end",
  },
  {
    title: "Documentation",
    desc: "Dive into the full documentation from components, tech stack, tests",
    link: "https://dev.react-doc.phpr.link/",
    icon: <HiOutlineLightBulb className="w-10 h-10 text-yellow-500" />,
    id: "documentation",
  },
];

var fqcn_bui = { Bundle: "homeBundle", Unit: "landing", Interface: "landing" };

const HomeContainer: React.FC = () => {
  const t = useTranslations("Home");
  const locale = useLocale();
  return (
    <div className="pb-5 min-h-[75vh] h-full flex items-center justify-center flex-col">
      <div className="text-center pt-5">
        <h1
          id={getFQCN(fqcn_bui, "title-landing")}
          className="text-2xl font-bold"
        >
          {t("title")}
        </h1>
        <p id={getFQCN(fqcn_bui, "subtitle-landing")}>{t("subtitle")}</p>
      </div>

      <div className=" pt-12 flex flex-wrap gap-8 md:p-12 justify-center place-items-center">
        {arrayNav.map((item) => (
          <div className="flex w-60 h-72" key={arrayNav.indexOf(item)}>
            <Link
              href={
                item.link.includes("http")
                  ? item.link
                  : locale === "fr"
                  ? `/fr/${item.link}`
                  : `/en/${item.link}`
              }
              target={item.link.includes("http") ? "_blank" : "_self"}
            >
              <div className="flex w-60 h-72">
                <Card
                  id={getFQCN(fqcn_bui, item.id + "-card")}
                  className="text-center border-gray-300 hover:border-blue-400 transform hover:scale-[1.02] transition-all ease-linear w-full"
                >
                  <CardHeader
                    id={getFQCN(fqcn_bui, item.id + "-cardHeader")}
                    className="flex justify-center items-center"
                  >
                    {item.icon}
                  </CardHeader>
                  <CardTitle id={getFQCN(fqcn_bui, item.id + "-cardTitle")}>
                    {t(item.title)}
                  </CardTitle>
                  <CardContent
                    id={getFQCN(fqcn_bui, item.id + "-cardContent")}
                    className="text-sm p-3"
                  >
                    {t(item.desc)}
                  </CardContent>
                  <CardFooter
                    id={getFQCN(fqcn_bui, item.id + "-cardFooter")}
                    className="flex justify-center items-center"
                  >
                    <div className="fixed bottom-0 mb-4">
                      <Button
                        className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue text-white"
                        id={getFQCN(fqcn_bui, item.id + "-button")}
                        title={t("Visit")}
                      >
                        {t("Visit")}
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeContainer;
